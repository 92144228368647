import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "masonry-container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-4" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "col-md-8" }
const _hoisted_6 = { class: "content" }
const _hoisted_7 = ["onClick"]

import { onMounted, ref } from 'vue';
import axios from 'axios';


export default /*@__PURE__*/_defineComponent({
  __name: 'GalleryComponent',
  props: {
  league: {
    type: String,
    required: true,
  },
},
  emits: ['onSelect'],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const props = __props;

const pictures = ref([]);
const selectedPicture = ref('');

const fetchPictures = async (): Promise<void> => {
  try {
    const response = await axios.get('/api/getAllPictures');
    pictures.value = response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der Bilder:', error);
  }
};

const selectPicture = async (picturePath: string) => {
  const fileName = picturePath.split('/').pop();
  if (!fileName) return;

  try {
    await axios.post(`/api/update-league-image/${props.league}`, {
      picturePath: fileName,
    });
    selectedPicture.value = picturePath;
    emit('onSelect', picturePath);
  } catch (error) {
    console.error('Fehler beim Aktualisieren des Liga-Bildes:', error);
  }
};

onMounted(async () => {
  await fetchPictures();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pictures.value, (picture, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "masonry-item",
        key: index
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: picture.picturePath,
              alt: 'Picture ' + index,
              class: _normalizeClass(["img-fluid", { selected: picture.picturePath === selectedPicture.value }])
            }, null, 10, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("button", {
                onClick: ($event: any) => (selectPicture(picture.picturePath))
              }, " Auswählen ", 8, _hoisted_7)
            ])
          ])
        ])
      ]))
    }), 128))
  ]))
}
}

})