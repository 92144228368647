<template>
  <section @contextmenu.prevent>
    <Dialog v-model:visible="visible" modal header="Spielinfos" :style="{ width: '25rem' }">
      <div class="flex items-center gap-4 mb-4">
        <!-- eslint-disable-next-line -->
        <label for="liga" class="font-semibold w-24">Liga</label>
        <br>
        <InputText id="liga" v-model="localData.liga" class="flex-auto" autocomplete="off" />
      </div>
      <br>
      <div class="flex items-center gap-4 mb-4">
        <!-- eslint-disable-next-line -->
        <label for="heim" class="font-semibold w-24">Heim</label>
        <br>
        <InputText id="heim" v-model="localData.heim" class="flex-auto" autocomplete="off" />
      </div>
      <br>
      <div class="flex items-center gap-4 mb-4">
        <!-- eslint-disable-next-line -->
        <label for="gegner" class="font-semibold w-24">Gegner</label>
        <br>
        <InputText id="gegner" v-model="localData.gegner" class="flex-auto" autocomplete="off" />
      </div>
      <br>
      <div class="flex items-center gap-4 mb-4">
        <!-- eslint-disable-next-line -->
        <label for="datum" class="font-semibold w-24">Datum</label>
        <br>
        <Calendar id="datum" v-model="localData.datum" class="flex-auto" date-format="dd.mm.yy" />
      </div>
      <br>
      <div class="flex items-center gap-4 mb-4">
        <!-- eslint-disable-next-line -->
        <label for="uhrzeit" class="font-semibold w-24">Uhrzeit</label>
        <br>
        <Calendar id="uhrzeit" v-model="localData.uhr"
                  class="flex-auto" time-only hour-format="24" />
      </div>
      <br>
      <div class="button-group flex justify-end gap-2">
        <Button style="margin-right: 2%;" type="button" label="Save" @click="saveChanges" />
        <Button type="button" label="Cancel" severity="secondary" @click="visible = false" />
      </div>
    </Dialog>

    <Dialog v-model:visible="isGalleryDialogVisible"
            modal header="Bild auswählen" :style="{ width: '95vw' }">
      <GalleryComponent league="bundesliga" @onSelect="updateLeagueImage" />
    </Dialog>

    <div class="control">
      <router-link to="/">
        <img src="/img/left.png" alt="left" class="controlpanel left">
      </router-link>
      <router-link to="/">
        <img src="/img/dots.png" alt="left" class="controlpanel dots">
      </router-link>
    </div>
    <div class="ticket-container">
      <SettingsComponent id="settings" @close="isSettingsVisible = false" v-if="isSettingsVisible"/>
      <p-card class="ticket-card option-view" v-if="isSettingsVisible" />
      <TicketInformations @closed="toggleVisible" v-else-if="showInfo" />
      <p-card class="ticket-card" v-else>
        <div class="info-bar">
          <img id="logo" :src="leagueImage"
               alt="logo" @click="openGalleryDialog"
               @keydown.enter="openGalleryDialog" style="cursor: pointer;" />
          <h2 @click="visible = true" @keydown.enter="visible = true"
              @keydown.space="visible = true">{{ liga }}</h2>
          <img id="information" @click="showInfo = true" @keydown.enter="showInfo = true"
               src="/img/information.png" alt="information" />
        </div>
        <div class="header-bar">
          <div class="animated-bar" />
          <img id="eintracht" src="/img/logo.png" alt="Eintracht Frankfurt Logo" />
          <div class="sponsors">
            <div id="deutschebank">
              <img src="/img/deutschebank.png" alt="Deutsche Bank Park" />
              <div class="deutsch-bank">
                <p>Deutsche Bank</p>
                <p>Park</p>
              </div>
            </div>
            <img id="indeed" src="/img/indeed.webp" alt="Indeed" />
            <img id="elotrans" class="inverter" src="/img/elotrans.png" alt="ELOTRANS" />
          </div>
        </div>
        <div class="content-placeholder">
          <h3>{{ heim }} vs. {{ gegner }}</h3>
          <div class="block-info">
            <p>Block</p>
            <h2>40</h2>
          </div>
          <div class="kurve-info">
            <p>Unterrang Nordwestkurve</p>
            <p>Mitglied</p>
          </div>
          <p class="date-info">{{ formattedDate }}, {{ uhr }} Uhr</p>
        </div>
        <div class="qr-and-u">
          <h1 class="large-u">U</h1>
          <div class="qr-code-placeholder">
            <img src="/img/qr-code.png" alt="QR Code">
          </div>
        </div>
      </p-card>
    </div>
    <div @click="isSettingsVisible = true" @keydown.enter="isSettingsVisible = true"
         class="burger-bar">
      <img src="/img/burger-bar.png" alt="burger" />
    </div>
  </section>
</template>

<script setup>
import {
  ref, reactive, onBeforeMount, computed,
} from 'vue';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import axios from 'axios';
import TicketInformations from '@/components/TicketInformations.vue';
import SettingsComponent from '@/components/SettingsComponent.vue';
import GalleryComponent from '@/components/GalleryComponent.vue';

const showInfo = ref(false);
const isSettingsVisible = ref(false);
const isGalleryDialogVisible = ref(false);

const liga = ref('no data');
const heim = ref('');
const gegner = ref('');
const datum = ref('');
const uhr = ref('');

const leagueImage = ref('/img/default_league_image.png');

const visible = ref(false);
const localData = reactive({
  liga: '',
  heim: 'no data',
  gegner: '',
  datum: '',
  uhr: '',
});

function toggleVisible(value) {
  showInfo.value = value;
}

function openGalleryDialog() {
  isGalleryDialogVisible.value = true;
}

function getPicture() {
  axios.get('/api/get-data/bundesliga')
    .then((response) => {
      const { data } = response;
      liga.value = data.liga;
      heim.value = data.heim;
      gegner.value = data.gegner;

      const [day, month, year] = data.datum.split('.');
      const date = new Date(year, month - 1, day);
      datum.value = date;

      uhr.value = data.uhr;

      localData.liga = data.liga;
      localData.heim = data.heim;
      localData.gegner = data.gegner;
      localData.datum = date;
      localData.uhr = data.uhr;
      datum.value = localData.datum;

      if (data.leagueImage) {
        leagueImage.value = `/api/pictures/${data.leagueImage}`;
      }
    })
    .catch((err) => {
      console.error('Error fetching data:', err);
    });
}

function updateLeagueImage() {
  getPicture();
  isGalleryDialogVisible.value = false;
}

function saveChanges() {
  let formattedDate = localData.datum;
  if (localData.datum !== datum.value) {
    const day = localData.datum.getDate().toString().padStart(2, '0');
    const month = (localData.datum.getMonth() + 1).toString().padStart(2, '0');
    const year = localData.datum.getFullYear();
    formattedDate = `${day}.${month}.${year}`;
  }

  let formattedTime = localData.uhr;
  if (localData.uhr !== uhr.value) {
    formattedTime = `${localData.uhr.getHours().toString().padStart(2, '0')}:${localData.uhr.getMinutes().toString().padStart(2, '0')}`;
  }

  const plainData = {
    liga: localData.liga,
    heim: localData.heim,
    gegner: localData.gegner,
    datum: formattedDate,
    uhr: formattedTime,
  };

  axios.post('/api/write-data/bundesliga', plainData)
    .then(() => {
      liga.value = localData.liga;
      heim.value = localData.heim;
      gegner.value = localData.gegner;
      datum.value = formattedDate;
      uhr.value = formattedTime;
      visible.value = false;
    })
    .catch((err) => {
      console.error('Error saving data:', err);
    });
}

onBeforeMount(() => {
  getPicture();
});

const formattedDate = computed(() => {
  if (datum.value instanceof Date) {
    return datum.value.toLocaleDateString('de-DE');
  }
  return datum.value;
});
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

section {
  position: relative;
  height: 95vh;
  max-height: 100vh !important;
}

.ticket-container {
  top: .5%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 75px);
  box-sizing: border-box;
}

.ticket-card {
  padding-top: 8px;
  width: 95%;
  height: 95%;
  max-width: 400px;
  min-width: 350px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #1f1f1f;
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.option-view {
  padding-top: 223%;
}

.info-bar {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #1f1f1f;
}

.info-bar img#logo {
  width: 50px;
  height: 50px;
  background-color: white;
  padding: 5px;
}

.info-bar img#information {
  width: 6.5%;
  filter: invert(.85);
  margin-top: -20px;
  padding-left: -20px !important;
}

.info-bar h2 {
  font-size: 1rem;
  flex: .95;
  text-align: left;
  margin: 0 25px;
}

.header-bar {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 250px;
  background-color: #000000;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
}

.header-bar img#eintracht {
  width: 22%;
}

.header-bar .sponsors {
  position: relative;
  left: -12.5px;
  top: 2% !important;
  display: flex;
  gap: 4px;
  margin-left: 10%;
}

#elotrans {
  padding: 7px;
}

#indeed {
  padding: 3px;
}

.header-bar .sponsors img {
  width: 75px;
  height: 32px;
  border: .5px solid white;
  border-radius: 5px;
  object-fit: contain;
}

.sponsors #deutschebank {
  display: flex;
  align-items: center;
  width: 75px;
  height: 32px;
  border: .5px solid white;
  border-radius: 5px;
  flex-shrink: 0;
}

.sponsors #deutschebank p {
  margin-left: -10%;
}

.sponsors #deutschebank img {
  width: 38%;
  border: none;
  margin-left: -3.5%;
}

.inverter {
  filter: invert(1) !important;
  border: .5px solid black !important;
}

.animated-bar {
  position: absolute;
  top: 0;
  left: -100%;
  width: 50px;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(-10deg);
  animation: move 1.5s linear infinite;
}

@keyframes move {
  from {
    left: -40px;
  }
  to {
    left: 100%;
  }
}

.content-placeholder {
  padding: 20px;
  text-align: left;
  flex-grow: 1;
}

.content-placeholder h3,
.content-placeholder p,
.content-placeholder h2 {
  margin: 10px 0;
  line-height: 1.2;
}

.block-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.block-info p,
.block-info h2 {
  margin: 0;
}

.kurve-info {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 20px;
}

.kurve-info p {
  margin: 0;
}

.date-info {
  margin-top: 20px;
  margin-bottom: 40px;
}

.qr-and-u {
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  margin-bottom: 5%;
}

.qr-and-u h1 {
  font-weight: normal;
  top: clamp(45%, 30%, 25%);
}

.qr-code-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
}

.qr-code-placeholder img {
  z-index: 5;
  width: 100%;
  height: 100%;
}

.large-u {
  position: absolute;
  padding-bottom: 10%;
  font-size: 3.5rem;
  font-family: Roboto, serif;
  left: calc(50% - 135px);
}

.controlpanel {
  position: fixed;
  filter: invert(1);
  width: 8%;
}

.control {
  z-index: 999;
  padding: 10px;
  align-items: center;
  justify-items: center;
  display: flex;
  justify-content: space-between;
  width: 95%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  max-width: 400px;
}

.burger-bar {
  z-index: 42;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  background-color: #b60e0e;
  bottom: -1px;
  position: absolute;
  right: calc(50% - 130px);
  transform: translateX(50%);
}

.burger-bar img {
  height: 50%;
  width: 35%;
  filter: invert(1);
}

#settings {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 95%;
  max-width: 400px;
  border-radius: 10px;
  overflow: hidden;
}

.deutsch-bank {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.deutsch-bank p {
  font-weight: bold;
  margin: 0;
  line-height: 1;
  font-size: .4rem;
}

.dots {
  right: -2%;
}
</style>
