// store/index.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    userCount: 0,
  },
  getters: {
    userCount: (state) => state.userCount,
  },
  mutations: {
    SET_USER_COUNT(state, count) {
      state.userCount = count;
    },
  },
  actions: {
    updateUserCount({ commit }, count) {
      commit('SET_USER_COUNT', count);
    },
  },
  modules: {
  },
});
