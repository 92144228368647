<script setup lang="ts">
import { onMounted, ref } from 'vue';
import axios from 'axios';

const emit = defineEmits(['onSelect']);
const props = defineProps({
  league: {
    type: String,
    required: true,
  },
});

const pictures = ref([]);
const selectedPicture = ref('');

const fetchPictures = async (): Promise<void> => {
  try {
    const response = await axios.get('/api/getAllPictures');
    pictures.value = response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der Bilder:', error);
  }
};

const selectPicture = async (picturePath: string) => {
  const fileName = picturePath.split('/').pop();
  if (!fileName) return;

  try {
    await axios.post(`/api/update-league-image/${props.league}`, {
      picturePath: fileName,
    });
    selectedPicture.value = picturePath;
    emit('onSelect', picturePath);
  } catch (error) {
    console.error('Fehler beim Aktualisieren des Liga-Bildes:', error);
  }
};

onMounted(async () => {
  await fetchPictures();
});
</script>

<template>
  <div class="masonry-container">
    <div class="masonry-item" v-for="(picture, index) in pictures" :key="index">
      <div class="row">
        <div class="col-md-4">
          <img
            :src="picture.picturePath"
            :alt="'Picture ' + index"
            class="img-fluid"
            :class="{ selected: picture.picturePath === selectedPicture }"
          />
        </div>
        <div class="col-md-8">
          <div class="content">
            <button @click="selectPicture(picture.picturePath)">
              Auswählen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.masonry-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  padding: 16px;
}

.masonry-item {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s;
}

.masonry-item:hover {
  transform: scale(1.05);
}

.row {
  display: flex;
  flex-direction: column;
  text-align: center;
}

img {
  max-width: 100%;
  height: 200px;
  object-fit: cover;
}

img.selected {
  border: 4px solid #007bff;
}

.content {
  padding: 16px;
}

button {
  color: white;
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:active {
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.3);
}
</style>
