import './registerServiceWorker';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import { createApp } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { io } from 'socket.io-client';
import store from './store';
import router from './router';
import App from './App.vue';
import 'primeicons/primeicons.css';

const app = createApp(App);

const socket = io('https://aqila.einfachmanu.de', { path: '/socket.io/' });

socket.on('userCount', (count) => {
  store.dispatch('updateUserCount', count);
});

app.provide('socket', socket);

app
  .use(store)
  .use(router)
  .use(PrimeVue, { theme: { preset: Aura } })
  .component('Dialog', Dialog)
  .component('Button', Button)
  .component('InputText', InputText)
  .mount('#app');
